#contact {
	background: #191919;
	padding-top: 96px;
	padding-bottom: 66px;

	a,
	a:visited {
		color: #11abb0;
	}
	a:hover,
	a:focus {
		color: #fff;
	}

	h1 {
		font: 18px/24px "opensans-bold", sans-serif;
		text-transform: uppercase;
		letter-spacing: 3px;
		color: #ebeeee;
		margin-bottom: 6px;

		&:before {
			font-family: "FontAwesome";
			content: "\f0e0";
			margin-right: 24px;
			font-size: 72px;
			line-height: 72px;
			color: #ebeeee;
		}

		span {
			display: none;
		}
	}

	h4 {
		font: 16px/24px "opensans-bold", sans-serif;
		color: #ebeeee;
		margin-bottom: 6px;
	}
}

#contact {
	.section-head {
		flex-direction: column;
		margin-bottom: 12px;
		.section-head__title {
			padding: 0 32px;

			h1 {
				font: 16px/24px "opensans-bold", sans-serif;
				text-align: center;
				margin: 0;
				display: flex;
				line-height: 72px;
				text-shadow: 0px 1px 3px rgba(0, 0, 0, 1);
				span {
					display: block;
				}
				&:before {
					font-size: 66px;
					line-height: 66px;
				}
			}
		}

		.section-head__description {
			padding: 0 32px;

			p.lead {
				font: 17px/33px opensans-light, sans-serif;
			}
		}
	}
}

#contact {
	form {
		margin: 0;

		label {
			font: 15px/24px "opensans-bold", sans-serif;
			margin: 12px 0;
			color: #ebeeee;
			display: inline-block;
			float: none;
			width: 100%;

			span {
				font-size: 13px;
				&.required {
					color: #11abb0;
				}
				&.error {
					color: #d72828;
				}
			}
		}

		input,
		textarea,
		select {
			padding: 18px 20px;
			color: #eee;
			background: #373233;
			margin-bottom: 6px;
			width: 100%;
			border: 0;
			outline: none;
			font-size: 15px;
			line-height: 24px;

			&:focus {
				color: #fff;
				background-color: #11abb0;
			}
		}

		textarea {
			resize: vertical;
		}
	}
}

#contact {
	#message-warning,
	#message-success {
		background: #0f0f0f;
		padding: 12px 24px;
		width: 100%;
		margin-left: 0;
	}
	#message-warning {
		color: #d72828;
	}
	#message-success {
		color: #11abb0;
	}

	#message-warning,
	#message-success {
		i {
			margin-right: 10px;
		}
	}
}

#contact {
	.submit-wrapper {
		display: flex;
		align-items: center;

		button.submit {
			text-transform: uppercase;
			letter-spacing: 3px;
			color: #fff;
			background: #0d0d0d;
			border: none;
			cursor: pointer;
			height: auto;
			display: inline-block;
			border-radius: 3px;
			margin: 30px 0 24px 0;
		}
		button.submit:hover {
			color: #0d0d0d;
			background: #fff;
		}
		#image-loader {
			position: relative;
			left: 18px;
		}
	}
}

@media only screen and (min-width: 768px) {
	#contact {
		padding-bottom: 102px;
		.section-head {
			.section-head__title {
				padding: 0 16px;
				h1 {
					span {
						display: none;
					}
				}
			}

			.section-head__description {
				padding: 0 20px;
				p.lead {
					font: 18px/36px "opensans-light", sans-serif;
				}
			}
		}

		form {
			label {
				float: left;
				width: 26%;
			}
			input,
			textarea,
			select {
				margin-bottom: 42px;
				width: 65%;
			}
		}

		.submit-wrapper {
			button.submit {
				margin: 0 0 0 26%;
			}
		}

		#message-warning,
		#message-success {
			width: 65%;
			margin-left: 26%;
		}
	}
}
