.work-experience {
   margin-bottom: 48px;
   padding-bottom: 24px;
   border-bottom: 1px solid #E8E8E8;

    .toggleShowMore {
        cursor: pointer;
    }

    .hidden {
        display: none;
    }

    .work-experience__date {
        font: 15px/24px 'opensans-regular', sans-serif;
        margin-top: 6px;
    }
}
