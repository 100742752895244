.header--loader {
    position: absolute;
    height: 100%;
}
.loader__container {
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:before {
        content: '';
        display: block;
    }

    .loader__icon {
        width: 100px;
        -webkit-animation: rotate 2s linear infinite;
                animation: rotate 2s linear infinite;
        -webkit-transform-origin: center center;
            -ms-transform-origin: center center;
                transform-origin: center center;

        .loader__path {
            stroke-dasharray: 150,200;
            stroke-dashoffset: -10;
            -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
                    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
            stroke-linecap: round;
        }
    }

    .loader__text {
        font: 22px/30px 'opensans-bold', sans-serif;
        color: #fff;
        margin-top: 48px;
        padding: 0 48px;
        letter-spacing: 1px;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, .5);
    }
}


@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}
@-webkit-keyframes color {
  0% {
    stroke: #11abb0;
  }
  40% {
    stroke: #11abb0;
  }
  66% {
    stroke: #11abb0;
  }
  80%, 90% {
    stroke: #11abb0;
  }
}
@keyframes color {
  0% {
    stroke: #11abb0;
  }
  40% {
    stroke: #11abb0;
  }
  66% {
    stroke: #11abb0;
  }
  80%, 90% {
    stroke: #11abb0;
  }
}
