.bars {
	width: 95%;
	float: left;
	padding: 0;
  text-align: left;
  
  .skills {
    margin-top: 36px;
    list-style: none;
  }

  li {
    position: relative;
  	margin-bottom: 60px;
  	background: #ccc;
  	height: 42px;
    border-radius: 3px;

    .bar-expand {
      position: absolute;
      left: 0;
      top: 0;
      margin: 0;
      padding-right: 24px;
      background: #313131;
      display: inline-block;
      height: 42px;
      line-height: 42px;
      border-radius: 3px 0 0 3px;
    }
    
    em {
	    font: 15px 'opensans-bold', sans-serif;
      color: #313131;
	    text-transform: uppercase;
      letter-spacing: 2px;
	    font-weight: normal;
      position: relative;
	    top: -36px;
    }
  }

  [data-aos="new-animation"] {
    opacity: 0;
    width: 0%;
    transition-property: opacity, width;

    &.aos-animate {
      opacity: 1;
      width: 100%;
    }
  }
}
