#portfolio.portfolio-container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #191919;
  padding-top: 90px;
  padding-bottom: 60px;
  font: 15px/24px "opensans-semibold", sans-serif;

  .portfolio__header {
    font: 15px/24px "opensans-semibold", sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 48px;
    color: #95a3a3;
  }

  .timeline {
    width: 80%;
    height: auto;
    max-width: 1000px;
    margin: 0 auto;
    position: relative;

    ul {
      list-style: none;

      li {
        padding: 20px;
        background-color: #1e1f22;
        color: white;
        border-radius: 10px;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .timeline-content {
      h1 {
        color: white;
        font-weight: 500;
        font-size: 25px;
        line-height: 30px;
        margin-bottom: 10px;
      }

      .timeline-content__link,
      .timeline-content__link:visited {
        margin-right: 16px;
        color: #f06000;
        &:hover {
          color: #11abb0;
        }
      }

      p {
        color: #838c95;
        font-size: 16px;
        line-height: 30px;
        font-weight: 300;
      }

      img {
        margin: 8px 0;
      }

      iframe {
        width: 100%;
      }

      .date {
        color: white;
        font-size: 12px;
        font-weight: 300;
        margin-bottom: 10px;
        letter-spacing: 2px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .timeline {
    &:before {
      content: "";
      position: absolute;
      top: 12px;
      left: 50%;
      transform: translateX(-50%);
      width: 2px;
      height: 100%;
      background-color: gray;
    }

    ul {
      li {
        width: 50%;
        position: relative;
        margin-bottom: 50px;

        &:hover::before {
          background-color: aqua;
        }

        &::before {
          content: "";
          position: absolute;
          height: 20px;
          width: 20px;
          border-radius: 50%;
          background-color: gray;
          top: 0px;
        }

        &:nth-child(odd) {
          left: -30px;
          float: left;
          clear: right;
          transform: translateX(-30px);
          border-radius: 20px 0px 20px 20px;

          &::before {
            right: -40px;
          }
        }

        &:nth-child(even) {
          right: -30px;
          float: right;
          clear: left;
          transform: translateX(30px);
          border-radius: 0px 20px 20px 20px;

          &::before {
            left: -40px;
          }
        }
      }
    }

    .timeline-content {
      .date {
        position: absolute;
        top: -30px;
      }
    }
  }
}
