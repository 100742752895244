.banner {
   display: inline-block;
   vertical-align: middle;
   margin: 0 auto;
   width: 85%;
   padding-bottom: 30px;
   text-align: center;

   .banner__container { 
      width: 100%;
      .social {
         display: flex;
         justify-content: center;
         align-items: center;
         margin: 24px 0;
         padding: 0;
         font-size: 30px;
         color: darkorange;
         text-shadow: 0px 1px 2px rgba(0, 0, 0, .8);

         li {
            display: inline-block;
            margin: 0 15px;
            padding: 0;

            a { 
               font-size: 48px;
               color: darkgray; 
               &:hover { color: #11ABB0; }
            }
         }
      }
      h1 {
         font: 90px/1.1em 'opensans-bold', sans-serif;
         color: #fff;
         letter-spacing: -2px;
         margin: 0 auto 18px auto;
         text-shadow: 0px 1px 3px rgba(0, 0, 0, .8);
      }
      h3 {
         font: 18px/1.9em 'librebaskerville-regular', serif;
         color: #A8A8A8;
         margin: 0 auto;
         width: 70%;
         text-shadow: 0px 1px 2px rgba(0, 0, 0, .5);

         span, a {
            color: #fff;
         }
      }
      
      hr {
         width: 60%;
         margin: 18px auto 24px auto;
         border-color: #2F2D2E;
         border-color: rgba(150, 150, 150, .1);
      }
   }
}

@media only screen and (max-width: 1024px) {
   .banner__container { 
      .banner__header{
         font-size: 50px;
      }
      h1 {
            font: 80px/1.1em 'opensans-bold', sans-serif;
            letter-spacing: -1px;
            margin: 0 auto 12px auto;
      }
   }  
}

@media only screen and (max-width: 900px) {
   .banner { 
      padding-bottom: 12px;
      .banner__container {
         h1 {
            font: 78px/1.1em 'opensans-bold', sans-serif;
            letter-spacing: -1px;      
         }
         h3 {
            font: 17px/1.9em 'librebaskerville-regular', serif;
            width: 80%;
         }
         hr {
            width: 65%;
            margin: 12px auto;
         }
      }
   }
}


@media only screen and (max-width: 767px) {
   .banner {
      padding-bottom: 12px;
      padding-top: 6px;

      .banner__container{
         .banner__header{
            font-size: 40px;
         }
         .social {
            margin: 18px 0 24px 0;
            font-size: 24px;
            line-height: 36px;
            li { margin: 0 10px; }     
         }
         h1 { font: 68px/1.1em 'opensans-bold', sans-serif; }
         h3 {
            font: 16px/1.9em 'librebaskerville-regular', serif;
            width: 85%;
         }
         hr {
            width: 80%;
            margin: 18px auto;
         }
      }
   }
}

@media only screen and (max-width: 480px) {
   .banner { 
      padding-top: 24px; 
      .banner__header{
            font-size: 35px;
      }
      .banner__container {
         h1 {
            font: 40px/1.1em 'opensans-bold', sans-serif;      
            margin: 0 auto 24px auto;
         }
         h3 {
            font: 14px/1.9em 'librebaskerville-regular', sans-serif;
            width: 90%;
         }
         .social { 
            font-size: 20px;
            li { 
               margin: 0 6px; 
            }
         }
      }
   }
}
